<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'productions-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer une nouvelle production
        </button>
      </div>

      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          Actualiser
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Date</th>
          <th scope="col">Total Quantité</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(production, index) in productions" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ production.reference }}
          </td>

          <td>{{ production.date }}</td>

          <td>
            <span v-if="production.totalQuantity != null" class="text-primary">
              {{ production.totalQuantity }}
            </span>
          </td>

          <td>
            <span v-if="production.user">
              {{ production.user.name }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'productions-details',
                  params: { reference: production.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("production", {
      productions: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("production/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("production/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("production/getAll");
    },

    async listProductions(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("production/getTodays");
      } else {
        return await this.$store.dispatch("production/getProductions");
      }
    },
    async sort(value) {
      return await this.$store.commit("production/sort", value);
    },
  },
};
</script>
